

import { IProject, IProjectFollowership, IProjectMembership, IRI, IUser, MembershipRole, ProjectProgress } from "@api/schema"
import { AppState } from "@redux/reducer"
import { selectCurrentUser } from "@redux/reducer/auth"
import { selectCollection, selectProjectBySlugOrId } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"
import { INNER_TEAM_ROLES, TEAM_ROLES } from "@services/projectHelper"
import { idFromIModelOrIRI, iriFromEntityTypeAndId, iriFromIModelOrIRI, stringToInt } from "@services/util"



export const selectMyMemberships = (state: AppState): IProjectMembership[] => {
  const user = selectCurrentUser(state)
  return user ? user.projectMemberships : null
}

export const selectMyFollowerships = (state: AppState): IProjectFollowership[] => {
  const user = selectCurrentUser(state)
  return user ? user.followerships : null
}

/**
 * Selector to retrieve the list of projects the user created
 *
 * @param state
 * @returns null if the user wasn't loaded yet, else the list of projects (may be empty)
 */
export const selectCreatedProjects = (state: AppState): IProject[] => {
  const user: IUser = selectCurrentUser(state)

  // to allow differentiating between "user not loaded" and "has no projects":
  if (!user) {
    return null
  }

  return user.createdProjects.filter(p => p.progress !== ProjectProgress.Idea)
}

/**
 * Selector to retrieve the list of ideas the user created
 *
 * @param state
 * @returns null if the user wasn't loaded yet, else the list of ideas (may be empty)
 */
export const selectCreatedIdeas = (state: AppState): IProject[] => {
  const user: IUser = selectCurrentUser(state)

  // to allow differentiating between "user not loaded" and "has no projects":
  if (!user) {
    return null
  }

  return user.createdProjects.filter(p => p.progress === ProjectProgress.Idea) // .map(p => state.data.project.get(p.id) as IProject)
}

/**
 * Selector to retrieve the list of project IDs the user is a member (or applicant) of.
 *
 * @param state
 * @returns null if the user wasn't loaded yet, else the list of IDs (may be empty)
 */
export const selectMyProjectIDs = (state: AppState): number[] => {
  const memberships = selectMyMemberships(state)

  // to allow differentiating between "user not loaded" and "has no memberships":
  if (memberships === null) {
    return null
  }
  if (memberships.length === 0) {
    return []
  }
  return memberships.map((membership) => idFromIModelOrIRI(membership.project))
}


/**
 * Returns a loaded project if one exists with the given slugOrId (from slug component in routes) where the user
 * is a full member (no applicant) and that was loaded with member privileges.
 */
export const selectProjectBySlugOrIdAsMember = (state: AppState, slugOrId: string): IProject => {
  const project = selectProjectBySlugOrId(state, slugOrId)
  if (!project || !project.usedMemberRole) {
    return null
  }

  return isProjectMember(state, project.id) ? project : null
}

/**
 * Returns the project stub from the current users memberships that matches the given slug or id
 * or null if nothing was found
 *
 * This function assumes, that the current users memberships are project stubs and not only IRIs
 */
export const selectProjectStubBySlugOrIdFromCurrentUsersMemberships =
  (state: AppState, slugOrId: string | number): IProject | null => {
    const currentUser = selectCurrentUser(state)

    // no slurOrId, no user or no memberships: return early
    if (!slugOrId || !currentUser || !currentUser.projectMemberships || currentUser.projectMemberships.length === 0) {
      return null
    }

    let membership: IProjectMembership = null

    // is slugOrId convertable to a number: than the id is already given
    const id = stringToInt(slugOrId as string)
    // if the id is not given search for the slug
    if (id) {
      membership = currentUser.projectMemberships.find(ms =>
        typeof ms.project !== "string" // NOTE: may be not necessary, b/c project should always be an IProject, @see https://futureprojects.atlassian.net/browse/FCP-1381
        && ms.project.id === id
      )
    } else {
      membership = currentUser.projectMemberships.find(ms =>
        typeof ms.project !== "string" // NOTE: may be not necessary, b/c project should always be an IProject, @see https://futureprojects.atlassian.net/browse/FCP-1381
        && ms.project.slug === slugOrId
      )
    }

    return membership?.project || null
  }

/**
 * Returns a loaded project if one exists with the given slugOrId (from slug component in routes) where the user
 * is an editing member (no applicant, no observer) and that was loaded with member privileges.
 */
export const selectProjectBySlugOrIdAsEditor = (state: AppState, slugOrId: string): IProject => {
  const project = selectProjectBySlugOrId(state, slugOrId)
  if (!project || !project.usedMemberRole) {
    return null
  }

  return isProjectEditor(state, project.id) ? project : null
}

/**
 * Selects the memberrole within the project
 *
 * @param state
 * @param projectID
 * @returns null,if the user has no membership within this project or the project could not be found, other returns the membership role
 */
export const selectProjectMemberRole = (state: AppState, projectID: number): MembershipRole => {
  const memberships = selectMyMemberships(state)
  if (!memberships || memberships.length === 0 || !projectID) {
    return null
  }

  const projectMembership = memberships
    .filter((m: IProjectMembership) => idFromIModelOrIRI(m.project) === projectID)
    .shift()

  return projectMembership ? projectMembership.role : null
}

/**
 * Checks, if the user has role within the project and is not an applicant for the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is a member of the project
 */
export const isProjectMember = (state: AppState, projectID: number): boolean => {
  const role = selectProjectMemberRole(state, projectID)
  return role !== null && role !== MembershipRole.Applicant
}

/**
 * Checks, if the user is a Coordinator or a Planner of the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is part of the INNER TEAM of the project
 */
export const isProjectEditor = (state: AppState, projectID: number): boolean => {
  const role = selectProjectMemberRole(state, projectID)
  return INNER_TEAM_ROLES.includes(role)
}

/**
 * Checks, if the user is a user has read access to the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is a part of the inner team
 */
export const isProjectReader = (state: AppState, projectID: number): boolean => {
  const role = selectProjectMemberRole(state, projectID)
  return TEAM_ROLES.includes(role)
}

/**
 * Checks, if the user is a Coordinator of the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is a Coordinator of the project
 */
export const isProjectCoordinator = (state: AppState, projectID: number): boolean =>
  selectProjectMemberRole(state, projectID) === MembershipRole.Coordinator

/**
 * Checks, if the user is a observer of the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is a observer of the project
 */
export const isProjectObserver = (state: AppState, projectID: number): boolean =>
  selectProjectMemberRole(state, projectID) === MembershipRole.Observer

/**
 * Checks, if the user is a planner of the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is a planner of the project
 */
export const isProjectPlanner = (state: AppState, projectID: number): boolean =>
  selectProjectMemberRole(state, projectID) === MembershipRole.Planner

/**
 * Checks, if the user is an applicant of the project
 *
 * @param state
 * @param projectID
 * @returns true, if the user is an applicant of the project
 */
export const isProjectApplicant = (state: AppState, projectID: number): boolean =>
  selectProjectMemberRole(state, projectID) === MembershipRole.Applicant

/**
 * Checks, if the given user is a follower of the project
 *
 * @param state
 * @param projectID
 * @param user the user to be checked
 * @returns true, if the user is an follower of the project
 */
export const isProjectFollower = (state: AppState, projectID: number, user: IUser | IRI): boolean =>
  !!selectProjectBySlugOrId(state, projectID.toString()).followerships.find(ship =>
    iriFromIModelOrIRI(ship.project) === iriFromEntityTypeAndId(EntityType.Project, projectID)
    && iriFromIModelOrIRI(ship.user) === iriFromIModelOrIRI(user))


/**
 * Select projects, which have certain proposal. The collection should currently always have one project.
 *
 * @param state
 * @param proposalIRI
 * @returns a collection with one project
 */
export const selectProjectByProposalIri = (state: AppState, proposalIRI: string): IProject =>
  selectCollection<IProject>(state, EntityType.Project)
    .find(p => p.proposals.find(a => a["@id"] === proposalIRI))