import { INumericIdentifierModel } from "@api/schema"


/**
 * This interface defines the possible state of a request to the API:
 * It may (still) be loading or not, and there may be loadingErrors.
 *
 * It is especially used by the scopedRequestReducer to build special states for every Entity-related request.
 */
export interface IRequestState {
  /** is the request currently loading? */
  isLoading: boolean
  /** error message if an error occured while the request */
  loadingError: string
  /** was the request finished after it was started? */
  loaded: boolean
}

/**
 * initial state based on IRequestState
 */
export const initialRequestState: IRequestState = {
  isLoading: false,
  loadingError: null,
  loaded: false,
}

/**
 * constant to be used when defining successful request state
 */
export const REQUEST_STATE_SUCCESSFUL: IRequestState = {
  loaded: true, // request is finished -> loaded!
  isLoading: false, // request is no longer loading
  loadingError: null, // there have been no errors
}


export interface IIndexedCollectionState<T extends INumericIdentifierModel> {
  [id: number]: T
}
export const initialIndexedCollectionState: IIndexedCollectionState<INumericIdentifierModel> = {}
