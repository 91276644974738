import jwtDecode from "jwt-decode"

import { IAuthReply, UserRole } from "@api/schema"
import { emptyAuthState, IJwtState } from "@redux/reducer/auth"

/**
 * schema of a decoded JWT
 */
export interface IDecodedToken {
  readonly exp: number
  readonly id: number
  readonly roles: UserRole[]
  readonly username: string // this is a custom property, supplied by API-Platform/LexikJWT
}

export const authReplyToJwtState = (auth: IAuthReply): IJwtState => {
  try {
    const decodedToken: IDecodedToken = jwtDecode(auth.token)

    // only if the token can be decoded
    return {
      jwt: auth.token,
      jwtExpiresAt: decodedToken.exp,
      refreshToken: auth.refresh_token,
      refreshTokenExpiresAt: auth.refresh_token_expires,
      roles: decodedToken.roles,
      userId: decodedToken.id,
      username: decodedToken.username,
    }
  }
  catch (err) {
    return emptyAuthState
  }
}

/**
 * Converts the given timestamp to a Date object.
 *
 * @param timestamp unix timestamp in seconds
 */
export const timestampToDate = (timestamp: number): Date => new Date(timestamp * 1000)

/**
 * Returns the number of seconds the given timestamp is in the future (positive values) or past (negative).
 *
 * @param timestamp unix timestamp in seconds
 */
export const timeDiff = (timestamp: number): number => timestamp - (new Date().getTime() / 1000)

/**
 * Returns true if the the given unix timestamp has passed, else false.
 *
 * @param timestamp unix timestamp in seconds
 */
export const timestampPassed = (timestamp: number): boolean => timeDiff(timestamp) <= 0