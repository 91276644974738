// import { ToastOptions } from "react-toastify"
import { TranslationQuery } from "next-translate"
import { ToastOptions } from "react-toastify"
import { v1 as uuidv1 } from "uuid"

import { namespaceFromI18nKeyWithDefaultFallback } from "@services/i18n"
import { isFirstOccurrenceOfValueInArray } from "@services/util"

import { NotificationActions, NotificationActionTypes } from "../actions/notifications"

export type NotificationWithButtonLinkConfig = {
  messageKey: string
  messageParams?: TranslationQuery
  linkTitleKey: string
  linkTitleParams?: TranslationQuery
  route: string
}

export type INotificationContentType = string | NotificationWithButtonLinkConfig

export interface INotification {
  content: INotificationContentType
  id: string
  options: ToastOptions
  params: TranslationQuery
}

export type NotificationState = INotification[]

export const initialNotificationState: NotificationState = []

/**
 * reduces the state to an array of notifications, that should be shown to the user by a toast
 *
 * @see ToastContainer: components/common/ToastContainer
 */
export const notificationReducer =
  (state: NotificationState = initialNotificationState, action: NotificationActions): NotificationState => {
    switch (action.type) {
      case NotificationActionTypes.AddNotification:
        const id: string = uuidv1()
        return [...state, {
          content: action.content,
          id,
          options: action.options || {},
          params: action.params || {},
        }]

      case NotificationActionTypes.RemoveNotification:
        return state.filter((element) => element.id !== action.id)

      default:
        return state
    }
  }

/**
 * Extract i18n keys from notifications in the state
 *
 * @param state The notification state
 * @returns The set of i18n keys that are used in the notification state
 */
export const keysFromNotificationState = (state: NotificationState): string[] => {
  return state.map(notification =>
    typeof (notification.content) === "string"
      ? notification.content
      : [
        // @todo most probably this should not return a "more processed" value than the "if" branch of this ?:
        namespaceFromI18nKeyWithDefaultFallback(notification.content.messageKey),
        namespaceFromI18nKeyWithDefaultFallback(notification.content.linkTitleKey)
      ]
  )
    .flat()
}

/**
 * Extract i18n namespaces from notifications in the state
 *
 * @param state The notification state
 * @returns The set of i18n namespaces that are used in the notification state
 */
export const namespacesFromNotificationState = (state: NotificationState): string[] => {
  return keysFromNotificationState(state)
    .map(key => namespaceFromI18nKeyWithDefaultFallback(key))
    .filter(isFirstOccurrenceOfValueInArray)
}