import stringify from "json-stable-stringify"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IModel, UserRole } from "@api/schema"
import { IFilterCriteria, newLoadCollectionAction, newLoadCollectionPageAction } from "@redux/helper/actions"
import { IFilteredCollectionState } from "@redux/helper/reducers"
import { AppState } from "@redux/reducer"
import { selectCollectionByIds, selectCollectionUsecaseState } from "@redux/reducer/data"
import { LoadableEntityType } from "@redux/reduxTypes"

/**
 * return type of the useEntityCollection hook
 */
export type UseEntityCollectionResult<Type extends IModel> = {
  /** collection of entities */
  entities: Type[]
  /** the request state when fetching the collection */
  request: IFilteredCollectionState
  /** the used usecase key, may be given or calculated */
  usecaseKey: string
  /** triggers the load of the next page if there is a next page to the (last) collection load */
  loadNextPage: () => void
  // @todo: ErrorOrSpinnerPage
}

/**
 * options to define details of the work mode of the useEntityCollection hook
 *
 * Default:
 * - do not load all pages
 * - load data immediately
 */
type UseEntityCollectionOptions = {
  /**
   * Should the hook load all entities/all pages?
   * NOTE: be careful, b/c that could be a lot of data!
   */
  loadAll?: boolean
  /**
   * If true, the hook does not dispatch API calls.
   * Used to avoid unnecessary API calls, e.g. if one of the needed filterCriteria is not given, because
   * needed data has not yet fetched from the API.
   *
   * Usage example:
   * useEntityCollection<IFeedbackInvitation>(
   * EntityType.FeedbackInvitation,
   * {
   * relatedObject: currentProcess?.["@id"],
   * receiver: FeedbackInvitationReceiver.All,
   * state: FeedbackInvitationState.All
   * },
   * // avoids loading when module is off or currentProcess is not available (yet)
   * { doNotLoad: !MODULE_CHALLENGE_AVAILABLE || !currentProcess }
   * )
   */
  doNotLoad?: boolean
  /**
   * Role that must be used when fetching the entities, e.g. to differentiate manager calls from user calls.
   *
   * NOTE: If the coder defines a usedRole in a component/page, that also is available for users
   * that do not have this role, the hook does not return an entity but a loadedWithWrongPrivilege error.
   */
  usedRole?: UserRole
}


/**
 * Hook for providing a collection of entity by its EntityType and filter params:
 * get it from a state if it is available or load it from the backend.
 *
 * It always uses a stringifyed version of the filter criteria as usecaseKey to make sure, that results for
 * similar requests are taken from the state instead of calling the API.
 *
 * @todo Error-Handling: sollte dieser Hook eine Error-Page rausgeben oder kümmert sich darum die aufrufende Komponente?
 * https://futureprojects.atlassian.net/browse/FCP-1361
 * @todo sobald useEntityCollection() alle Collection-Abrufe ersetzt, sollen getItems() und getLoadNextPageAction()
 * am IFilteredCollectionState entfernt werden: https://futureprojects.atlassian.net/browse/FCP-1445
 * @todo Tests zeigen: die Saga setzt bei Fehlern beim IFilteredCollectionState "loaded = false". Loaded sollte wohl
 * eher in "finished" umbenannt werden: https://futureprojects.atlassian.net/browse/FCP-1304
 *
 * NOTE: filterCriteria Data that is not altered by the user but by the calling component MUST NOT change on every render!
 * example: filterCriteria with date properties that are set with "new Date()" e.g. for "submissionEnd[strictly_after]": new Date()
 * Such data will change the usecaseKey on every render and will lead to an infinite-loop-problem.
 * Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect, but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render.
 * at ChallengeSelectionPage (/develop/pages/projects/[slug]/challenge-proposal/select-challenge.tsx:40:31)
 */
export const useEntityCollection = <Type extends IModel>(
  entityType: LoadableEntityType,
  criteria: IFilterCriteria = {},
  options?: UseEntityCollectionOptions
): UseEntityCollectionResult<Type> => {
  const dispatch = useDispatch()

  // use a usecase key if given or create the key from the criteria + usedRole (if set)
  // to be reusable in case of multiple calls with the same criteria and role
  // using stringify of package "json-stable-stringify" to order the criteria properties so that same criteria
  // with different order always lead to the same usecase key: https://www.npmjs.com/package/json-stable-stringify
  const usecaseKey = stringify(criteria) + (options?.usedRole ?? "")

  // get the (possibly loaded) entities from the state
  const request = useSelector((state: AppState) => selectCollectionUsecaseState(state, entityType, usecaseKey))
  const entities = useSelector((state: AppState) => selectCollectionByIds<Type>(state, entityType, request.itemIds))

  const loadNextPage = (): void => {
    if (request.nextLink && !request.isLoading) {
      // @todo: must newLoadCollectionPageAction respect options.usedRole?
      dispatch(newLoadCollectionPageAction(entityType, request.nextLink, usecaseKey))
    }
  }

  useEffect(() => {
    // trigger the loading
    // if loading has not happened yet
    // if loading is not already running
    // if there was no error
    if (
      (!request || !request.loaded) && !request.isLoading && !request.loadingError
      && !options?.doNotLoad
    ) {
      dispatch(newLoadCollectionAction(
        entityType,
        criteria,
        usecaseKey,
        options?.loadAll,
        options?.usedRole
      ))
    }
  }, [
    JSON.stringify(request),
    // Trigger useEffect as soon as the filterCriteria changes
    // b/c usecaseKey is calculated from the filterCriteria.
    usecaseKey,
    // useEffect is triggered as soon as the options change, b/c the mode could change from
    // "do not load" to "load now" -> independed of criteria change, e.g. when the opening of a card should trigger the loading
    JSON.stringify(options)
  ])

  return { request, entities, usecaseKey, loadNextPage }
}